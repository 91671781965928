import { Dispatch, compose } from 'redux';
/**
 *
 * CheckoutPage
 *
 */
import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { goToStep, setCheckoutStatus, trackOrdersCompletedById } from './actions';
import { selectCart, selectOrderDisabled, selectPendingApprovalMode } from 'containers/MainLayout/selectors';
import selectCheckoutPage, { selectCheckoutStatus } from './selectors';

import Button from 'components/Button/Button';
import Checkout from 'components/Checkout/Checkout';
import EmptyCartImage from './EmptyCart.svg';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import OrderCompleteModal from './OrderComplete';
import PageHeader from 'components/PageHeader/PageHeader';
import { RootState } from './types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import media from 'utils/mediaStyle';
import messages from './messages';
import moment from 'moment';
import { push } from 'connected-react-router';
import reducer from './reducer';
import saga from './saga';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import uuid from 'uuid/v4';

interface OwnProps {
  checkoutpage: any;
  cart: any;
  isPendingApproval: boolean;
  isOpenSubmitModal: boolean;
  checkoutStatus: any;
  orderDisabled: {
    orderDisabled: boolean;
    message: string;
  };
}

interface StateProps {}

interface DispatchProps {
  onGoToCheckoutStep: (step: number) => void;
  onGoToOrders: (url) => void;
  onTrackOrdersCompleted: (ordersId) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(max-width: 991px) {
    align-items: unset;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.sm`
  flex-direction: row;
  `};
`;

const Space = styled.div`
  width: 15px;
  height: 15px;
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h2 {
    font-size: 32px;
    margin-top: 30pt;
    margin-bottom: 20pt;
  }
  img {
    margin-top: 100pt;
    max-width: 100vw;
  }
  p {
    font-size: 14px;
    margin-bottom: 12pt;
  }
`;

const CheckoutPage: FC<Props> = (props) => {
  const {
    checkoutpage,
    onGoToCheckoutStep,
    onGoToOrders,
    cart,
    checkoutStatus,
    isPendingApproval,
    orderDisabled,
    onTrackOrdersCompleted,
  } = props;

  useEffect(() => {
    if (window.localStorage.getItem('checkout_id')) {
      const checkoutId = `checkout_${uuid()}`;
      window.localStorage.setItem('checkout_id', checkoutId);
    }
  }, []);

  const isCheckoutSuccess = useMemo(
    () => checkoutStatus && checkoutStatus.status === 'CHECKOUT_SUCCESS',
    [checkoutStatus],
  );

  const handleTrackOrderCompleted = useCallback(
    (orders) => {
      if (orders.length) {
        onTrackOrdersCompleted(orders.map(({ id }) => id));
      }
    },
    [onTrackOrdersCompleted],
  );

  if (cart.loading) {
    return <LoadingIndicator />;
  }

  return (
    <Fragment>
      {!cart.cartSet.length ? (
        <Container>
          <Empty>
            <h2>
              <FormattedMessage {...messages.emptyCart} />
            </h2>
            <p>
              <FormattedMessage {...messages.goBacktoMartPrompt} />
            </p>
            <ButtonContainer>
              <Link to="/market">
                <FormattedMessage {...messages.goToMart}>
                  {(goToMart: string) => (
                    <FormattedMessage {...utilsMessages.language}>
                      {(language: string) => (
                        <Button type="primary" width={language === 'vi' ? '175px' : '140px'}>
                          {goToMart}
                        </Button>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              </Link>
              <Space />
              <Link to="/favorite">
                <FormattedMessage {...messages.goToFavorites}>
                  {(goToFavorites: string) => (
                    <FormattedMessage {...utilsMessages.language}>
                      {(language: string) => (
                        <Button type="primary" width={language === 'vi' ? '175px' : '140px'}>
                          {goToFavorites}
                        </Button>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              </Link>
            </ButtonContainer>
            <img src={EmptyCartImage} alt="empty cart" />
          </Empty>
        </Container>
      ) : (
        <Container>
          <PageHeader center>
            <FormattedMessage {...messages.header} />
          </PageHeader>
          <Checkout
            loading={checkoutpage.loading}
            onGoToCheckoutStep={onGoToCheckoutStep}
            orderDraftToken={checkoutpage.orderDraftToken}
            orderDisabled={orderDisabled.orderDisabled}
            orderDisabledMessage={orderDisabled.message}
          />
        </Container>
      )}
      <OrderCompleteModal
        isOpen={isCheckoutSuccess}
        isPendingApproval={isPendingApproval}
        onSubmit={onGoToOrders}
        orders={checkoutStatus.orders}
        onTrackOrdersCompleted={handleTrackOrderCompleted}
      />
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  checkoutpage: selectCheckoutPage(),
  cart: selectCart(),
  checkoutStatus: selectCheckoutStatus(),
  isPendingApproval: selectPendingApprovalMode(),
  orderDisabled: selectOrderDisabled(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onGoToCheckoutStep: (step: number) => dispatch(goToStep(step)),
    onGoToOrders: (isPendingApproval) => {
      const today = moment();
      dispatch(
        push(
          isPendingApproval
            ? '/orders-pending-approval'
            : `/orders?orderDateStart=${today.startOf('day').toISOString()}&orderDateEnd=${today
                .endOf('day')
                .toISOString()}`,
        ),
      );
      dispatch(
        setCheckoutStatus({
          status: 'DRAFT',
        }),
      );
    },
    onTrackOrdersCompleted: (orderIds) => dispatch(trackOrdersCompletedById(orderIds)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'checkoutPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'checkoutPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(CheckoutPage);
